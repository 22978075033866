body {
  margin: 0;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


@keyframes p {
    from{--p:0}
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  display: flex;
  background-image: linear-gradient(80deg,rgb(53, 92, 182), 60%, #149b3f);
  padding: 0;
  align-items: center;
  height: 80px;
}

.footer {
  background: #007e8f;
  position: relative;
  width: 100%;
  margin-top: 20px;
  text-align: right;
}
.main-container {
  min-height: calc(100vh - 160px);
}

.header ul {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.header ul li {
  margin: 10px;
  color: white;
  font-size: 14px;
  color: white;
}

.main-logo {
  font-size: 50px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  color: white;
  margin-right: 20px;
  box-sizing: content-box;
}

.logo {
  height: 55px;
  padding: 10px;
  box-sizing: content-box;
}

.logo-lider {
  height: 40px;
  margin-right: 5px;
}
.logo-ncbir {
  height: 40px;
  margin: 0 0 0 15px;
}

.logo-wchpk {
  height: 40px;
  margin: 0px 15px;
}

.logo-pk {
  height: 40px;
  margin: 0px;
}

.logo-w {
  filter: brightness(5);
}
